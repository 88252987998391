// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// NOTE: This file is for staging builddev ONLY.

export const environment = {
  production: false,
  appName: 'URMU',
  apiBaseUrl: 'https://urmu.it-predator.com/api/',
  fileBaseUrl: 'https://urmu.it-predator.com/data/',
  apiKey: 'a9e5a70a4cc82083337e6fb0820762394b1f35a1702e08b8ac826e0e4aa9a79b',
  stripeKey: 'pk_test_51JCxYPLx2dnoswhA4408yvnZILMiQJwsDSZsdYkwG5nDnbwKwgWB1a0c8HIS9AImpsMOUoVRYIXyCFBWFq7Jqn7F00CvgjAJij',
  mailchimpApi: 'https://it-predator.us5.list-manage.com/subscribe/post-json?u=4c61fa4e08ef9407921963017&amp;id=34b33ccd08&',
  mailchimpHiddenInput: 'b_4c61fa4e08ef9407921963017_34b33ccd08',
  razerPayment:{
    apiUrl:'sandbox.merchant.razer.com',
    paymentUrl:'sandbox.merchant.razer.com',
    merchantID:'SB_isurmu',
    verifyKey:'944f205cee0ffa8313e1fa1efbeb3b21',
    privateKey:'941415d71fbdd7db278ea9ab8e94d3c0'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

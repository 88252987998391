import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { AuthenticateService } from 'src/app/service/authenticate.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(
    // private authService: AuthenticateService,
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const token = this.authService.token();
    return next.handle(
      request.clone({ 
        setHeaders: {
          // "Authorization": `Bearer ${token}`,
          "X-Auth-Key": environment.apiKey,
         } 
      })
    );
  }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SanitizeHtmlPipe } from './pipe/sanitizeHtml';
import { TruncatePipe } from './pipe/truncateText';
import { FilterPipe } from './pipe/filterText';

@NgModule({
  declarations: [
    SanitizeHtmlPipe,
    TruncatePipe,
    FilterPipe,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [ 
    SanitizeHtmlPipe,
    TruncatePipe,
    FilterPipe,
  ],
})
export class SharedModule { }
